import React, { useEffect, useRef, useState } from 'react';
import LoyaltySliderContent from './LoyaltySliderContent';
import LoyaltyTierHeader from './LoyaltyTierHeader';
import { useDispatch, useSelector } from 'react-redux';
import { updateBasket } from '../../store/basketSlice';
import LoaderBlack from '../../img/common/Loader_360x360.gif';
import LoaderWhite from '../../img/common/Loader_360x360_White.gif';
import { getTierDisplayName } from '../../helpers/loyaltyHelpers';
import { useLazyGetCustomerStateQuery } from '../../services/application.api';
import { useConfig } from '../../helpers/useConfig';
import { useSafeState } from '../../hooks/useIsMounted';
import { gtmEvent } from '../../helpers/commonHelpers';
import { useSaveDiscountsMutation } from '../../services/loyalty.api';

const isSafari = navigator.userAgent.indexOf('Safari') > -1;

export const getSelectedRewards = (rewards) => {
  const selectedRewards = [];
  rewards.sections.forEach(section => {
    section.subSections.forEach(subSection => {
      subSection.offers.forEach(offer => {
        if (offer.selected && offer.selectedByThisUser) selectedRewards.push(offer);
      });
    });
  });
  return selectedRewards;
};

const LoyaltySlider = ({
  hasVouchersInBasket,
  onGetRewards,
  discountTotal,
  isDelivery,
  rewardData,
  setRewardData,
  tempBasket,
  setTempBasket,
  setInitialTempBasketHash
}) => {
  const config = useConfig();
  const dispatch = useDispatch();
  const inApp = useSelector(state => state.session.inApp);
  const rewards = useSelector(state => state.loyalty?.rewards);
  const offerRewards = useSelector(state => state.loyalty?.offerRewards);
  const tierName = useSelector(state => state.loyalty?.customer?.currentTier.name);
  const userFriendlyTiername = getTierDisplayName(tierName);
  const [loading, setLoading] = useSafeState(true);
  const [updating, setUpdating] = useState(false);
  const errorRef = useRef(null);
  const [getCustomerState] = useLazyGetCustomerStateQuery();
  const [initialised, setInitialised] = useState(false);
  const [saveDiscounts] = useSaveDiscountsMutation();
  const shapeDataAndSetRewards = (rewards) => {
    rewards.alternativeOverMessage = rewards.excludedCampaignIds?.length > 0 && !rewards.nonExcludedCampaignsSelectedByThisUser;
    setRewardData(rewards);
    if (onGetRewards)
      onGetRewards(rewards);
  };

  useEffect(() => {
    const getData = async () => {
      if (rewards && userFriendlyTiername) {
        const rewardsResponse = await fetch(`/api/basket/GetRewardsForTransientBasket/${!inApp}`);
        const rewards = await rewardsResponse.json();
        if (rewards.updateBasketResponse) {
          dispatch(updateBasket(rewards.updateBasketResponse));
          if (inApp) {
            setInitialTempBasketHash(rewards.tempBasketHash);
            setTempBasket({
              tempBasketJSON: rewards.tempBasketJSON,
              tempBasketHash: rewards.tempBasketHash
            });
          }
        }
        setLoading(false);
        shapeDataAndSetRewards(rewards);
      } else {
        await getCustomerState();
      }
      setInitialised(true);
    };
    if (initialised) return; // Prevent double fetch - MV
    getData();
  }, [rewards, userFriendlyTiername]);

  const handleTicketClick = async (offer, index, sectionIndex) => {
    setUpdating(true);
    if (!offer.selectedByThisUser) {
      const rewardsResponse = await fetch(
        `/api/basket/selectitem/${offer.offerId}/${sectionIndex}`,
        {
          method: 'POST',
          body: inApp ? JSON.stringify(tempBasket) : null,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (rewardsResponse.status == 500) return;

      const rewards = await rewardsResponse.json();
      if (rewards.updateBasketResponse) {
        dispatch(updateBasket(rewards.updateBasketResponse));
        setTempBasket({
          tempBasketJSON: rewards.tempBasketJSON,
          tempBasketHash: rewards.tempBasketHash
        });
      }

      shapeDataAndSetRewards(rewards);
    }
    else {
      const rewardsResponse = await fetch(
        `/api/basket/deselectitem/${offer.offerId}/${sectionIndex}`,
        {
          method: 'POST',
          body: inApp ? JSON.stringify(tempBasket) : null,
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
        });
      if (rewardsResponse.status == 500) return;

      const rewards = await rewardsResponse.json();
      if (rewards.updateBasketResponse) {
        dispatch(updateBasket(rewards.updateBasketResponse));
        setTempBasket({
          tempBasketJSON: rewards.tempBasketJSON,
          tempBasketHash: rewards.tempBasketHash
        });
        if (inApp) {
          const result = await saveDiscounts({tempBasketJSON: rewards.tempBasketJSON, tempBasketHash: rewards.tempBasketHash});
          if (result.updateBasketResponse) {
            dispatch(updateBasket(result.updateBasketResponse));
          }
        }
      }
      shapeDataAndSetRewards(rewards);
    }
    setUpdating(false);
  };

  const disabledBecauseOfVouchers = (hasVouchersInBasket?.length > 0 && !!hasVouchersInBasket);

  useEffect(() => {
    if (!disabledBecauseOfVouchers && discountTotal !== 0 && rewardData?.over > 0 && errorRef.current) {
      if (isSafari)
        errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      else
        errorRef.current.scrollIntoView(true);
    }
  }, [disabledBecauseOfVouchers, discountTotal, rewardData?.over]);

  useEffect(() => {
    gtmEvent(isDelivery ? 'Delivery_Loyalty' : 'Collection_Loyalty');
  }, []);

  return (
    <>
      {rewards && userFriendlyTiername ?
        <>
          <div className={`loyalty-slider-container reward-tier-${tierName.toLowerCase()}`}>
            {(!disabledBecauseOfVouchers && discountTotal !== 0 && rewardData?.over > 0) && !inApp &&
              <div className="loyalty-slider-warning" ref={errorRef}>
                Basket total under {config.CURRENCY_SYMBOL}10 by {config.CURRENCY_SYMBOL}{rewardData.over.toFixed(2)}
                {!rewardData.alternativeOverMessage && `.  You need to spend a minimum of ${config.CURRENCY_SYMBOL}10 to redeem your rewards.`}
                {rewardData.alternativeOverMessage && ' however you have some rewards available for redemption.'}
              </div>}
            {!inApp && disabledBecauseOfVouchers && <div className="loyalty-slider-warning">
              Please remove the discount vouchers before adding your rewards
            </div>}
            {((loading)) ?
              <div style={{ alignContent: 'center', flexDirection: 'column', display: 'flex', alignItems: 'center', paddingTop: '128px', paddingBottom: '128px' }}>
                <img src={tierName === 'SignUp' ? LoaderWhite : LoaderBlack} style={{ width: 64, height: 64 }} alt="logo" />
              </div>
              :
              <>
                <LoyaltyTierHeader tierName={userFriendlyTiername} rewardData={rewardData} inApp={inApp}/>
                <LoyaltySliderContent disabled={disabledBecauseOfVouchers} data={rewardData} onTicketClick={handleTicketClick} updating={updating} colourInverted={tierName === 'SignUp'} tierName={tierName} rewardsInfo={[...rewards, ...offerRewards]} inApp={inApp}/>
              </>
            }
          </div>
        </>
        :
        <div style={{ alignContent: 'center', flexDirection: 'column', display: 'flex', alignItems: 'center', paddingTop: '128px', paddingBottom: '128px' }}>
          <img src={tierName === 'SignUp' ? LoaderWhite : LoaderBlack} style={{ width: 64, height: 64 }} alt="logo" />
        </div>}
    </>
  );
};

export default LoyaltySlider;
