import { memo, useEffect, useState, MutableRefObject, useRef, lazy, Suspense } from 'react';
import { Route, useNavigate, useLocation, useMatch, Routes, useParams, useSearchParams } from 'react-router-dom';
import HomeController from './features/home/HomeController';
import NavMenu from './components/NavMenu';
import Footer from './components/Footer';
import { useGetCustomerStateQuery, useLazyGetApplicationStateQuery, useLazyGetCustomerStateQuery } from './services/application.api';
import { CloseIFrameModalWrapper, CollectionTimeErrorModalWrapper, DeliveryErrorModalWrapper, NotificationModalWrapper } from './helpers/modalHelpers';
import Loading from './components/Loading';
import { historyMW } from './helpers/routingHelpers';
import OrderJourneyController from './OrderJourneyController';
import CookiesBanner from './components/CookiesBanner';
import { useLogoutMutation, useChangeRestaurantMutation } from './services/customer.api';
import { initialiseFreshRelevance, setAppInsets, setInApp, setIsSmallScreen, setIsTabletScreen, setSessionSearchTerm } from './store/sessionSlice';
import { useSendToHome } from './hooks/useSendToHome';
import { CompareRewards } from './components/loyalty/CompareRewards';
import { getCookie, gtmEvent } from './helpers/commonHelpers';
import { setHasSeenVoucherExceptionModal } from './store/basketSlice';
import { useClearBasketDeliveryMutation, useClearBasketMutation } from './services/basket.api';
import { checkCanSaveBasket } from './helpers/basketHelpers';
import { postMessageWithAwait } from './helpers/appHelpers';
import { useConfig } from './helpers/useConfig';
import { useBreakPoint } from './hooks/useBreakPoint';
import useWindowDimensions from './hooks/useWindowDimensions';
import { DomRefs } from './types/RefTypes';
import { useAppDispatch, useAppSelector } from './store/storeTypes';
import { Basket, Customer } from './types/ApplicationTypes';

const ResetPassword = lazy(() => import('./features/checkout/pages/ResetPassword'));
let timeout = null as NodeJS.Timeout | null;

export const hasCustomerDetails = (basket: Basket, customer: Customer) =>
  basket?.email && customer == null || (customer != null && customer.mobileNumber);

export const existsButNeedsMobileNumber = (customer: Customer) => (customer != null && !customer.mobileNumber);

const AppController = memo(() => {
  const config = useConfig();
  const [getApplicationState, { isLoading: appStateLoading, isFetching: appStateFetching, isUninitialized }] = useLazyGetApplicationStateQuery();
  const [logout] = useLogoutMutation();
  const [changeRestaurant] = useChangeRestaurantMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const restaurantIdMatchDelivery = useMatch('/delivery/menu/:restaurantId/*');
  const restaurantIdMatchCollection = useMatch('/collection/menu/:restaurantId/*');
  const isCustomise = useMatch('/:journey/menu/:restaurantId/customise/:itemid');
  const isBundle = useMatch('/:journey/menu/:restaurantId/bundle/:itemid');
  const { loyalty } = useAppSelector(state => state.session.features);
  const { restaurant, collectionTime, customer, cookiesAccepted, features, inApp, hasSeenBasket, cmsConfig, searchTerm, isTabletScreen, isSmallScreen } = useAppSelector(state => state.session);
  // @ts-ignore Need to cleanup basketSlice.js to remove these type checks
  const { isDelivery, delivery, voucherExceptions, hasSeenVoucherExceptionModal, order, numberOfItems, id } = useAppSelector(state => state.basket);
  const [showChangeRestaurantModal, setShowChangeRestaurantModal] = useState(false);
  const [showCloseIframeModal, setShowCloseIframeModal] = useState(false);
  const [clearBasket] = useClearBasketMutation();
  const sendToHome = useSendToHome();
  window.initialiseFreshRelevance = () => dispatch(initialiseFreshRelevance());
  const [showNotification, setShowNotification] = useState(null as any);
  const [disablePaymentBackButton, setDisablePaymentBackButton] = useState(false);
  const [clearBasketDelivery] = useClearBasketDeliveryMutation();
  const offHomePage = pathname !== '/delivery' && pathname !== '/collection';
  const [prevPathname, setPrevPathname] = useState('/');
  const routeRestaurantId = (restaurantIdMatchDelivery?.params?.restaurantId || restaurantIdMatchCollection?.params?.restaurantId) ?? restaurant?.id;
  const [didomiObject, setDidomiObject] = useState(undefined);
  const menuRestaurantId = useAppSelector(state => state.menu.restaurantId);
  const menuHash = useAppSelector(state => state.menu.hash);
  const showLoginBanner = customer === null && (restaurantIdMatchDelivery || restaurantIdMatchCollection) && loyalty && routeRestaurantId === menuRestaurantId.toString() && !isCustomise && !isBundle;
  const { isLoading: customerStateLoading } = useGetCustomerStateQuery(routeRestaurantId);
  const [getCustomerState] = useLazyGetCustomerStateQuery();
  const isLoading = !config || appStateLoading || customerStateLoading;

  const refs: MutableRefObject<DomRefs> = useRef({
    ignoreScrollEvent: false,
    navRef: null,
    headerRef: null,
    menuContainerRef: null,
    backButtonRef: null,
    bannerRef: null,
    menuHeaderRef: null,
    footerRef: null
  } as DomRefs);

  useEffect(() => {
    const getData = async () => {
      const state = await getApplicationState({ restaurantId: routeRestaurantId, menuHash });
      await getCustomerState(routeRestaurantId);
      if (state?.data?.basket?.expiredBasket && pathname.includes('/delivery/')) {
        navigate('/delivery');
      }
    };
    if (!restaurant?.id || (routeRestaurantId && restaurant?.id !== routeRestaurantId) || pathname !== prevPathname && offHomePage) {
      setPrevPathname(pathname);
      getData();
    }
  }, [routeRestaurantId, restaurant?.id, pathname]);

  const handleChangeRestaurant = async () => {    
    gtmEvent(isDelivery ? 'Delivery_Change_Restaurant' : 'Collection_Change_Restaurant');
    await changeRestaurant(undefined);
    sendToHome();
  };

  const refreshBasket = () => {
    gtmEvent(isDelivery ? 'Delivery_Refresh_Basket' : 'Collection_Refresh_Basket');
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    timeout = setTimeout(() => {
      getApplicationState({ restaurantId: routeRestaurantId, menuHash });
    }, 5000);
  };

  const { width } = useWindowDimensions();
  const currentSmallScreen = useBreakPoint(width, 530);
  const currentTabletScreen = useBreakPoint(width, 768);
  useEffect(() => {
    if (isSmallScreen != currentSmallScreen) dispatch(setIsSmallScreen(currentSmallScreen));
    if (isTabletScreen != currentTabletScreen) dispatch(setIsTabletScreen(currentTabletScreen));
  }, [currentSmallScreen, currentTabletScreen, isSmallScreen, isTabletScreen, setIsSmallScreen, setIsTabletScreen]);

  const handleLogOut = async () => {
    gtmEvent(isDelivery ? 'Delivery_Logout' : 'Collection_Logout');
    sendToHome();
    await logout(undefined);
    await getApplicationState({ restaurantId: routeRestaurantId, menuHash });
  };

  useEffect(() => {
    if (!hasSeenVoucherExceptionModal && voucherExceptions?.length) setShowNotification({
      heading: 'We’re Sorry!',
      description: voucherExceptions?.length > 1 ?
      // @ts-ignore
        `Vouchers have been removed from your basket as they are no longer valid./n${voucherExceptions.map(v => `*${v.code}*/n`).join('')}` :
        // @ts-ignore
        `A voucher has been removed from your basket as it is no longer valid/n*${voucherExceptions[0].code}*`,
      redirectFn: async () => dispatch(setHasSeenVoucherExceptionModal(undefined)),
      redirectCta: 'Continue'
    });
  }, [hasSeenVoucherExceptionModal && voucherExceptions]);

  const getSaveBasket = () => {
    if (order || restaurant === null) return null;
    else return {
      journey: isDelivery ? 'delivery' : 'collection',
      journeyStep: hasSeenBasket && numberOfItems > 0 ? 'basket' : 'menu',
      basketId: id,
      restaurantId: restaurant?.id,
      restaurantClosingTime: restaurant?.closingTime
    };
  };

  const closeIframe = async (dontSaveBasket: boolean) => {
    if (dontSaveBasket) {
      window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'CLOSE_OOP' }));
      return;
  }
    const data = {
      type: 'CLOSE_OOP',
      basket: getSaveBasket()
    };
    window.ReactNativeWebView?.postMessage(JSON.stringify(data));
  };

  useEffect(() => {
    if (inApp) {
      const defaultLog = console.log;
      const defaultError = console.error;
      console.log = (...args) => {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'LOG', data: args }));
        defaultLog(...args);
      };
      console.error = (...args) => {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'ERROR', data: args }));
        defaultError(...args);
      };
      const bannerLinks = cmsConfig?.banners?.map((b: any) => b.ctaLink)?.filter((url: string) => !url.includes('order.pizzaexpress') || !url.includes(window.location.hostname)) ?? [];
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'EXTERNAL_LINKS',
          data: [
            ...bannerLinks,
            'www.pizzaexpress.com/allergens-and-nutritionals',
            'www.pizzaexpress.com/help-and-contact',
            'www.pizzaexpress.com/about-us/cookie-policy',
            'www.pizzaexpress.com/terms-of-use',
            'www.pizzaexpress.com/privacy-policy',
            'www.pizzaexpress.com/terms-and-conditions/online-ordering'
          ]
        })
      );
    }
  }, [cmsConfig?.banners]);

  useEffect(() => {
    if (!isLoading && window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'HIDE_LOADER' }));
    }
  }, [isLoading]);

  const showLeaveOOPModal = async (url: Location) => {
    setShowNotification({
      heading: 'Are you sure?',
      description: 'Leaving this page will result in you losing the contents of your basket.',
      redirectFn: async () => {
        await clearBasket(undefined);
        window.location = url;
      },
      redirectCta: 'Leave',
      cancel: 'Stay'
    });
  };

  useEffect(() => {
    const handleAppMessage = async (m: any) => {
      try {
        if (m.data) {
          const data = JSON.parse(m.data);
          if (data.type === 'SWITCH_JOURNEY') {
            if (data.journey === 'collection') {
              await clearBasketDelivery(undefined);
            }
            dispatch(setSessionSearchTerm(''));
            historyMW.push(`?postcode=${searchTerm}`, data.journey === 'delivery', navigate);
          }
        }
      } catch {
        return;
      }
    };

    if (inApp) {
      window.addEventListener('message', handleAppMessage);
      document.addEventListener('message', handleAppMessage);

      window.didomiEventListeners = window.didomiEventListeners || [];
      window.didomiEventListeners.push({
        event: 'consent.changed',
        listener: () => {
          const cookieValue = getCookie('euconsent-v2');
          window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'EUCONSENT', value: cookieValue }));
        }
      });
    }

    const getInsets = async () => {
      try {
        const { insets } = await postMessageWithAwait({ type: 'OOP_INITIALISED' }, ['INSETS']);
        dispatch(setAppInsets(insets));
      } catch {
        console.error('No insets returned from app');
      }
    };
    getInsets();

    return () => {
      window.removeEventListener('message', handleAppMessage);
      document.removeEventListener('message', handleAppMessage);
    };
  }, []);

  const loading = () => <Loading show inApp={inApp} />;
  if (isLoading) {
    return loading();
  }

  return (
    <>
      <div
        className={`wrapper navigation-margin`}
        style={{ display: 'flex', flexDirection: 'column', width: '100vw', flex: 1 }}
      >
        <NavMenu
          refs={refs}
          showLoginBanner={showLoginBanner}
          onLogOut={handleLogOut}
          inApp={inApp}
          offHomePage={offHomePage}
          handleCloseIframe={() => numberOfItems > 0 && order === null && checkCanSaveBasket(restaurant?.closingTime) ? setShowCloseIframeModal(true) : closeIframe(true)}
          leaveOOPHandler={showLeaveOOPModal}
          disablePaymentBackButton={disablePaymentBackButton}
        />
        <Routes>
          <Route path='/' element={
            <HomeController
              showChangeRestaurantModal={showChangeRestaurantModal}
              setShowChangeRestaurantModal={setShowChangeRestaurantModal}
            />
          } />
          <Route path='/collection' element={
            <HomeController
              showChangeRestaurantModal={showChangeRestaurantModal}
              setShowChangeRestaurantModal={setShowChangeRestaurantModal}
            />
          } />
          <Route path='/delivery' element={
            <HomeController
              showChangeRestaurantModal={showChangeRestaurantModal}
              setShowChangeRestaurantModal={setShowChangeRestaurantModal}
            />
          } />
          <Route path='/collection/*' element={
            <OrderJourneyController
              refs={refs}
              routeRestaurantId={routeRestaurantId}
              setShowChangeRestaurantModal={setShowChangeRestaurantModal}
              refreshBasket={refreshBasket} 
              setDisablePaymentBackButton={setDisablePaymentBackButton}
              disablePaymentBackButton={disablePaymentBackButton}
              isAppStateFetching={appStateFetching || appStateLoading || isUninitialized} />
          } />
          <Route path='/delivery/*' element={
            <OrderJourneyController
              refs={refs}
              routeRestaurantId={routeRestaurantId}
              setShowChangeRestaurantModal={setShowChangeRestaurantModal}
              refreshBasket={refreshBasket}
              setDisablePaymentBackButton={setDisablePaymentBackButton}
              disablePaymentBackButton={disablePaymentBackButton}
              isAppStateFetching={appStateFetching || appStateLoading || isUninitialized} />
          } />
          <Route path='/resetPassword/:state/:token' element={
            <Suspense fallback={loading()}>
              <ResetPassword />
            </Suspense>
            } />
          {
            features?.showRewardComparePage
              ? <Route path='/test/comparerewards' element={<CompareRewards />} />
              : null
          }
        </Routes>
      </div>
      {!inApp && !pathname.includes('/bundle') && !pathname.includes('/customise') && <Footer refs={refs} inApp={inApp} isTabletScreen={isTabletScreen} spacerRefName={'footerSpacer'} refName={'footerRef'}/>}
      <CollectionTimeErrorModalWrapper inApp={inApp} show={collectionTime === 'passed'} screenWidth={undefined} />
      {/* @ts-ignore */ }
      <DeliveryErrorModalWrapper inApp={inApp} show={(isDelivery && delivery && delivery?.deliveryQuote?.error) ?? false} message={delivery?.deliveryQuote?.error} onClose={handleChangeRestaurant} />
      <CookiesBanner visible={!cookiesAccepted} inApp={inApp} didomiObject={didomiObject} setDidomiObject={setDidomiObject} />
      <NotificationModalWrapper
        show={showNotification}
        onClose={() => setShowNotification(false)}
        notificationState={showNotification}
        inApp={inApp}
      />
      <CloseIFrameModalWrapper
        onClose={() => setShowCloseIframeModal(false)}
        show={showCloseIframeModal}
        redirect={() => historyMW.push('', isDelivery || pathname.includes('/delivery'), navigate)}
        closeIframe={closeIframe}
        inApp={inApp}
      />
    </>
  );
});

AppController.displayName = 'AppController'
export default AppController;