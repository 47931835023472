import { CSSProperties, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import AppController from './AppController';
import { useLocation } from 'react-router-dom';
import { freshRelevance } from './helpers/commonHelpers';
import { useConfig } from './helpers/useConfig';
import { useAppSelector } from './store/storeTypes';
import './styles/custom.css';
import './styles/payment.css';
import './styles/bulma_production.min.css';
// Use the following if you want to enable source maps for debugging
// import './styles/bulma_development.min.css';
// import './styles/bulma_development.css';

let oldPathname = '';

const App = () => {
  const inApp = useAppSelector(state => state.session.inApp);
  const appInsets = useAppSelector(state => state.session.appInsets);
  const { pathname } = useLocation();
  const config = useConfig();

  useEffect(() => {
    if (config?.GTM_ID) {
      TagManager.initialize({
        gtmId: config.GTM_ID
      });
    }
  }, [config?.GTM_ID]);

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });

    !config?.BRAND
      ? document.title = 'Order Online'
      : config.BRAND === 'PE'
        ? document.title = 'Order Online | PizzaExpress'
        : document.title = 'Order Online | Milano';

    TagManager.dataLayer({
      dataLayer: {
        event: 'Changed pathname',
        from: oldPathname,
        to: pathname,
        channel: inApp ? 'App' : 'Web'
      }
    });
    freshRelevance('pageChange', null, { 'sendBeacon': true });
    oldPathname = pathname;
  }, [pathname, config?.BRAND]);

  return (
    <div id="app-wrapper" className={`app-wrapper`}>
      <AppController />
      <span style={{ display: 'none' }}>Version: {config?.VERSION}</span>
    </div>
  );
};

export default App;
