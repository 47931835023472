export const pathMW = (url, isDelivery) => isDelivery ? `/delivery${url}` : `/collection${url}`;

export const historyMW = {
  push: (url, isDelivery, navigate, state) => {
    return isDelivery ? navigate(`/delivery${url}`, {state}) : navigate(`/collection${url}`, {state});
  },
  replace: (url, isDelivery, navigate, state) => {
    return isDelivery ? navigate(`/delivery${url}`, {state}) : navigate(`/collection${url}`, {state});
  }
};
